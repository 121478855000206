import React from "react"

import HorizontalTwoColumns from "../../components/Bulma/HorizontalTwoColumns"
import { Text, Image } from "../../components/Core"
import { Button, ButtonGroup } from "../../components/Button"

function InTheCommunity({ heading, blurb, image, buttons }) {
  return (
    <>
      <HorizontalTwoColumns
        className="in-the-community"
        leftColWidth={2}
        rightColWidth={3}
        middleColWidth={1}
        leftColumn={
          <div className="column community-text-block">
            {heading && <h3>{heading}</h3>}
            <Text text={blurb} className="community-markdown" />
            <ButtonGroup noReverse className="community-button">
              {buttons.map(btn => (
                <Button
                  key={btn.button.href}
                  contained
                  buttonText={btn.button.buttonText}
                  href={btn.button.href}
                />
              ))}
            </ButtonGroup>
          </div>
        }
        rightColumn={
          <div className="column">
            <Image publicId={image} responsive />
          </div>
        }
      />
    </>
  )
}

export default InTheCommunity
